import React, { ReactNode, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { supportDeviceSize } from "../components/styles";
import theme from "../theme";
import { useRecoilState } from "recoil";
import { splashState } from "../recoil/splash";
import { useNavigate } from "react-router-dom";

/**
 * @type { ReactNode }
 * 페이지 컨텐츠를 삽입하는 props
 */
type Props<P = unknown> = P & { children?: ReactNode };

/**
 * 다른 페이지에서도 디폴트 레이아웃으로 사용되는 컴포넌트
 * @param { ReactNode } children
 */
const DefaultLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [isFirst, setIsFirst] = useRecoilState(splashState);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
      navigate(`/splash`);
    }
  }, [isFirst]);

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Container>{children}</Container>
      </Root>
    </ThemeProvider>
  );
};

/**
 * 페이지의 기본 뼈대가 되는 레이아웃중, 제일 바깥의 컨테이너
 */
const Root = styled.div`
  display: flex;
  min-width: 100vw;
  /* min-height: 100vh; */
  align-items: flex-start;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  @media all and (max-width: ${supportDeviceSize}px) {
    width: 100vw;
  }
`;

/**
 * 페이지의 기본 뼈대가 되는 레이아웃중, 실제 컨텐츠(페이지 내용)을 담는 컨테이너
 */
const Container = styled.div`
  max-width: 360px;
  min-width: 280px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
`;

export default DefaultLayout;
