type themeType = {
  [key: string]: string;
};

const colors: themeType = {
  black: "#000000",
  gray900: "#121212",
  gray800: "#1c1b1f",
  gray700: "#1c2129",
  gray600: "#303030",
  gray500: "#303742",
  gray400: "#49454f",
  gray300: "#666666",
  gray250: "#999999",
  gray200: "#cccccc",
  gray150: "#d3d3d4",
  gray100: "#dbdbdb",
  gray80: "#ededed",
  gray50: "#f2f2f2",
  gray10: "#f6f6f6",
  gray5: "#f8f8f8",
  white: "#ffffff",
  purple500: "#5e0094",
  purple400: "#7e33a9",
  purple300: "#dfccea",
  purple200: "#f6f0f9",
  warning500: "#ec6608",
  yellow500: "#fee500",
} as const;

const theme = { colors } as const;
export default theme;
