import React, { useEffect, useState } from "react";
import { IModalStates } from "../../../components/Modal";
import {
  NetworkModalContainer,
  NetworkModalOfflineContent,
  NetworkModalConnectingContent,
  NetworkModalOnlineContent,
} from "./styles";

interface INetworkModal {
  isOnline: boolean;
}

interface IConnecting {
  setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>;
}

const NetworkModalOffline = ({ setIsConnecting }: IConnecting) => {
  // 클릭 시 새로고침 모달 렌더
  const handleClick = () => {
    setIsConnecting(true);
  };

  return (
    <NetworkModalOfflineContent>
      <img
        src={
          "https://elasticbeanstalk-ap-northeast-2-440345170327.s3.ap-northeast-2.amazonaws.com/artrivers/icons/ic_network_disconnected.svg"
        }
        alt="네트워크 연결 실패 아이콘"
      />
      <span>{`네트워크에\n연결되지 않았습니다.`}</span>
      <span>연결 확인 후 다시 시도해주세요.</span>
      <button onClick={handleClick}>새로고침</button>
    </NetworkModalOfflineContent>
  );
};

const NetworkModalConnecting = ({ setIsConnecting }: IConnecting) => {
  useEffect(() => {
    setTimeout(() => {
      // offline : 5초 후 연결 중 모달 렌더링
      setIsConnecting(false);
    }, 5000);
  }, []);

  return (
    <NetworkModalConnectingContent>
      <img
        src={
          "https://elasticbeanstalk-ap-northeast-2-440345170327.s3.ap-northeast-2.amazonaws.com/artrivers/icons/ic_network_connecting.svg"
        }
        alt="네트워크 연결 중 아이콘"
      />
      <span>네트워크 연결중</span>
    </NetworkModalConnectingContent>
  );
};

const NetworkModalOnline = () => {
  return (
    <NetworkModalOnlineContent>
      <img
        src={
          "https://elasticbeanstalk-ap-northeast-2-440345170327.s3.ap-northeast-2.amazonaws.com/artrivers/icons/ic_network_connected.svg"
        }
        alt="네트워크 연결 성공 아이콘"
      />
      <span>네트워크 연결 완료</span>
    </NetworkModalOnlineContent>
  );
};

const NetworkModal = ({ isOnline, setOpen }: IModalStates & INetworkModal) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOnline) {
      // online : 1초 후 모달 닫음
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  }, [isOnline]);

  return (
    <NetworkModalContainer>
      {isOnline ? (
        <NetworkModalOnline />
      ) : isConnecting ? (
        <NetworkModalConnecting setIsConnecting={setIsConnecting} />
      ) : (
        <NetworkModalOffline setIsConnecting={setIsConnecting} />
      )}
    </NetworkModalContainer>
  );
};

export default NetworkModal;
