import { createGlobalStyle } from "styled-components";

export const supportDeviceSize = 1080;

export const GlobalStyle = createGlobalStyle`
html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

  /* @media all and (max-width: ${supportDeviceSize}px) {
      font-size: 31.25%;
  } */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
    --vh: 100%;
  }
body {
  background: #ffffff;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', 'Roboto', -apple-system, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: -0.05em;
}
button,
input,
textarea {
  font-family: 'Noto Sans KR', 'Roboto', -apple-system, sans-serif;
  letter-spacing: -0.05em;
}
input,
input:focus,
input:active,
textarea,
text-area:focus,
text-area:active,
button,
button:focus,
button:active {
  box-shadow: none; 
  outline: none;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  display: block;
}
ul,ol,li {
	list-style: none;
	padding-left: 0px;
}
/* Chrome, Safari, Edge, Opera에서
숫자input 옆에 화살표 상자 비표시 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox 숫자input 화살표 비표시*/
input[type='number'] {
  -moz-appearance: textfield;
}
`;
