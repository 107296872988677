import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import NetworkModal from "./modal";

const Network = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine); // 네트워크 상태
  const [open, setOpen] = useState(!navigator.onLine); // 네트워크 모달 보이기/숨기기 플래그
  const [isPreloaded, setIsPreloaded] = useState(false); // 모달 내 이미지 프리로딩 여부

  const preloadImages = async (srcArr: string[]) => {
    const promises = await srcArr.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;

        img.onload = function () {
          resolve(img);
        };

        img.onerror = img.onabort = function () {
          reject(src);
        };
      });
    });

    await Promise.all(promises);
    setIsPreloaded(true);
  };

  // 위부터 차례대로 연결 실패, 연결 중, 연결 성공 아이콘 이미지 url
  useEffect(() => {
    const preloadImgSrcList: string[] = [
      "https://elasticbeanstalk-ap-northeast-2-440345170327.s3.ap-northeast-2.amazonaws.com/artrivers/icons/ic_network_disconnected.svg",
      "https://elasticbeanstalk-ap-northeast-2-440345170327.s3.ap-northeast-2.amazonaws.com/artrivers/icons/ic_network_connecting.svg",
      "https://elasticbeanstalk-ap-northeast-2-440345170327.s3.ap-northeast-2.amazonaws.com/artrivers/icons/ic_network_connected.svg",
    ];
    preloadImages(preloadImgSrcList);
  }, []);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine) {
        setOpen(true);
      }
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return isPreloaded && open ? (
    <Modal
      open={open}
      setOpen={setOpen}
      isNetwork={true}
      children={<NetworkModal isOnline={isOnline} setOpen={setOpen} />}
    />
  ) : null;
};

export default Network;
