import React, { useEffect, useRef, ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { supportDeviceSize } from "../styles";

// 사용법 예시 이슈 링크: [https://github.com/neordinary/artrivers-react-ts-web/issues/25]

// 모달 디스플레이 상태 interface
export interface IModalStates {
  open?: boolean;
  setOpen: (boolean: boolean) => void;
}

// IModalStates interface에 ReactNode 추가
interface IModalProps extends IModalStates {
  children: ReactNode;
  isNetwork?: boolean;
}

// DefaultLayout.tsx의 Root와 비슷한 역할을 하는 div
const ModalRoot = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  min-width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  align-items: flex-start;
  justify-content: center;
  background-color: transparent;
  z-index: 400;

  @media all and (max-width: ${supportDeviceSize}px) {
    width: 100vw;
  }
`;

// 모달의 어두운 배경 (클릭 시 모달창 닫힘)
const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  max-width: 36rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// 커스텀 모달을 만들 때 최상위 StyledComponent에 import해서 감싸주세요.
export const ModalContainer = styled.div`
  z-index: 500;
`;

const Modal = ({
  open = false,
  setOpen,
  isNetwork = false,
  children,
}: IModalProps) => {
  const el = document.querySelector("#modal-root") as HTMLElement;
  const modalOverlayRef = useRef<HTMLDivElement>(null);

  // 모달 오버레이 클릭 시 모달창 닫기 함수
  const modalOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (modalOverlayRef.current === e.target) {
      setOpen(isNetwork);
    }
  };

  // 모달 오픈 시 페이지 스크롤 방지
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      {open ? (
        <ModalRoot>
          <ModalOverlay
            ref={modalOverlayRef}
            onClick={(e) => modalOverlayClick(e)}
          >
            {children}
          </ModalOverlay>
        </ModalRoot>
      ) : null}
    </>,
    el
  );
};

export default Modal;
