import styled from "styled-components";
import { ModalContainer } from "../../../components/Modal";
import theme from "../../../theme";

export const NetworkModalContainer = styled(ModalContainer)`
  width: 32rem;
  background-color: ${theme.colors.white};
  padding: 2rem;
  border-radius: 1.2rem;
  z-index: 999;
`;

const NetworkModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  img {
    width: 6rem;
    height: 6rem;
  }

  span {
    white-space: pre-wrap;
    text-align: center;
    letter-spacing: -0.005em;
  }
`;

export const NetworkModalOfflineContent = styled(NetworkModalContent)`
  span:first-of-type {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${theme.colors.gray900};
  }

  span:last-of-type {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${theme.colors.gray250};
  }

  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28rem;
    height: 4.8rem;
    background-color: ${theme.colors.purple500};
    border: none;
    border-radius: 0.8rem;
    color: ${theme.colors.white};
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    letter-spacing: -0.005em;
  }
`;

export const NetworkModalConnectingContent = styled(NetworkModalContent)`
  span {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${theme.colors.gray900};
  }
`;

export const NetworkModalOnlineContent = styled(NetworkModalContent)`
  span {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${theme.colors.gray900};
  }
`;
