import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import routes from "./routes";
import { debounce } from "lodash";
import Loading from "./pages/loading";

//컴포넌트
const App = () => {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  const handleResize = debounce(() => {
    setScreenSize();
  }, 500);

  useEffect(() => {
    setScreenSize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={<DefaultLayout children={<route.element />} />}
                />
              )
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
