import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const Login = React.lazy(() => import("./pages/login/"));
const LoginRedirect = React.lazy(() => import("./pages/loginRedirect/"));
const Join = React.lazy(() => import("./pages/join/"));
const MyPage = React.lazy(() => import("./pages/myPage/MyPage"));
const Profile = React.lazy(() => import("./pages/myPage/Profile"));
const MyPlace = React.lazy(() => import("./pages/myPage/MyPlace"));
const ServiceInfo = React.lazy(() => import("./pages/myPage/ServiceInfo"));
const Search = React.lazy(() => import("./pages/search"));
const MyReservation = React.lazy(() => import("./pages/myReservation"));
const Place = React.lazy(() => import("./pages/place"));
const PlaceList = React.lazy(() => import("./pages/place/list"));
const PlaceDetail = React.lazy(() => import("./pages/placeDetail"));
const PlaceDetailMap = React.lazy(
  () => import("./pages/placeDetail/content/information/map/modal")
);
const Default = React.lazy(() => import("./pages/reservation/Default"));
const Project = React.lazy(() => import("./pages/reservation/Project"));
const Attachment = React.lazy(() => import("./pages/reservation/Attachment"));
const Success = React.lazy(() => import("./pages/reservation/Success"));
const Splash = React.lazy(() => import("./pages/splash"));

const routes = [
  { path: "/*", element: Home },
  { path: "/login", element: Login },
  { path: "/login/redirect/kakao", element: LoginRedirect },
  { path: "/login/redirect/google", element: LoginRedirect },
  { path: "/join/*", element: Join },
  { path: "/mypage", element: MyPage },
  { path: "/mypage/profile", element: Profile },
  { path: "/mypage/myplace", element: MyPlace },
  { path: "/mypage/service", element: ServiceInfo },
  { path: "/search", element: Search },
  { path: "/mypage/my-reservation/*", element: MyReservation },
  { path: "/place", element: Place },
  { path: "/place/:id", element: PlaceList },
  { path: "/place/detail/:id", element: PlaceDetail },
  { path: "/place/detail/:id/map", element: PlaceDetailMap },
  { path: "/reservation/default", element: Default },
  { path: "/reservation/project", element: Project },
  { path: "/reservation/attachment", element: Attachment },
  { path: "/reservation/success", element: Success },
  { path: "/splash", element: Splash },
];

export default routes;
