import React from "react";
import styled from "styled-components";
import theme from "../../theme";
import ImageLoading from "../../assets/images/img_loading.webp";

export default function Loading() {
  return (
    <LoadingRoot>
      <div>
        <img src={ImageLoading} alt="아트리버스" />
        <p>화면을 불러오는 중 입니다.</p>
      </div>
    </LoadingRoot>
  );
}

const LoadingRoot = styled.div`
  & div {
    background-color: ${theme.colors.white};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 700;
    color: ${theme.colors.gray900};
    white-space: nowrap;
    & img {
      height: 30px;
      width: 259px;
    }
  }
`;
